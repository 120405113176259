import { createSignal, onCleanup, onMount, Switch as SolidSwitch, Match } from "solid-js";
import { Box, IconButton, Stack } from "@suid/material";
import { ExpandIcon, CollapsIcon } from '../icons';

//<IconButton onClick={handleMinimize} size="small" ><Fa icon={faOutdent} color={"lightgrey"} /></IconButton>
//<IconButton onClick={handleMaximize} size="small"><Fa icon={faIndent} color={"lightgrey"} /></IconButton>

interface ResizeComponentProps {
    handleResize: (direction: string) => void;
    handleMinimize: (direction: string) => void;
    handleMaximize: (direction: string) => void;
}

const ResizeComponent = (props: ResizeComponentProps) => {

    const [isDragging, setDragging] = createSignal(false);
    const [isMovingLeft, setMovingLeft] = createSignal(false);
    const [isMovingRight, setMovingRight] = createSignal(false);
    const [prevX, setPrevX] = createSignal(0);
    const [canMinimize, setCanMinimize] = createSignal(true);
    const [canMaximize, setCanMaximize] = createSignal(true);

    // {"No" | "Maximize" | "Minimize" | "Both"}
    const [showButton, setShowButton] = createSignal("Maximize");

    const onMouseDown = (e: MouseEvent) => {
        setDragging(true);
        setPrevX(e.clientX);
        document.body.style.cursor = 'ew-resize'; // Set the cursor
        document.body.style.userSelect = 'none'; // Prevent text selection
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
    };

    const onMouseMove = (e: MouseEvent) => {
        if (!isDragging()) return;
        const delta = e.clientX - prevX();
        if (delta < -10) {
            setMovingLeft(true);
            setMovingRight(false);
            // handleResize("left");
        }
        if (delta > 10) {
            setMovingLeft(false);
            setMovingRight(true);
            // handleResize("right");
        }
    };

    const onMouseUp = () => {
        setDragging(false);
        if (isMovingLeft()) props.handleResize("left");
        if (isMovingRight()) props.handleResize("right");
        //  toast("Mouse UP: left: " + isMovingLeft() + " right: " + isMovingRight(), { duration: 8000, })
        setMovingLeft(false); setMovingRight(false);
        setIsEntering(false);
        document.body.style.cursor = ''; // Reset the cursor
        document.body.style.userSelect = ''; // Allow text selection again
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
    };


    const [isEntering, setIsEntering] = createSignal(false);
    const handleIsEntering = () => {
        setIsEntering(true);
    };
    const handleIsLeaving = () => {
        setIsEntering(false);
    };
    const handleMinimize = () => {
        setShowButton("Maximize");
        setIsEntering(false);
        props.handleMinimize("left");
    };
    const handleMaximize = () => {
        setShowButton("Minimize");
        setIsEntering(false);
        props.handleMaximize("right");
    };
    onMount(() => {
        // window.addEventListener('mousemove', onMouseMove);
        // window.addEventListener('mouseup', onMouseUp);
    });

    onCleanup(() => {
        // window.removeEventListener('mousemove', onMouseMove);
        // window.removeEventListener('mouseup', onMouseUp);
    });

    //alignItems='top'
    return (
        <>
            <Box
                alignItems='right'
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    py: 3
                }}
            >
                {
                    <SolidSwitch>
                        <Match when={showButton() === "Minimize"}>
                            <IconButton onClick={handleMinimize} size="small" ><CollapsIcon color="disabled" /></IconButton>
                        </Match>
                        <Match when={showButton() === "Maximize"}>
                            <IconButton onClick={handleMaximize} size="small"><ExpandIcon color="disabled" /></IconButton>
                        </Match>
                    </SolidSwitch>
                }
            </Box>
            <Box
                alignItems='center'
                backgroundColor={isEntering() ? 'lightgrey' : undefined}
                style={{
                    display: 'flex',
                    width: isDragging() ? '4px' : '2px',
                    cursor: 'ew-resize',
                }}
                onMouseEnter={handleIsEntering}
                onMouseLeave={handleIsLeaving}
            >
                <Box
                    alignItems='center'

                    style={{
                        cursor: 'ew-resize',
                        position: 'fixed', // makes the element positioned relative to the viewport

                    }}>
                    {
                        <Stack>

                            {/*     <IconButton size="small" onMouseDown={onMouseDown} sx={{ cursor: 'ew-resize', }}><Fa icon={faGripLinesVertical} color={"lightgrey"} /></IconButton>

                            <IconButton onClick={handleMinimize} size="small" ><Fa icon={faAnglesLeft} color={"lightgrey"} /></IconButton>
                            <IconButton onClick={handleMaximize} size="small"><Fa icon={faAnglesRight} color={"lightgrey"} /></IconButton>
                      */}
                        </Stack>
                    }
                </Box>
            </Box>

        </>
    );
}

/*
  style={{
                        cursor: 'ew-resize',
                        position: 'fixed', // makes the element positioned relative to the viewport
                        top: '300px', // sets the top edge from the top of the viewport
                    }}>
*/

/*
disable={canMinimize() ? false : true}
disable={canMaximize() ? true : false}

style={{
    display: isDragging() ? 'block' : 'none',
    position: 'fixed',
    top: 0,
    bottom: 0,
    width: '4px',
    left: `${prevX()}px`,
    cursor: 'ew-resize'
}}
*/

export { ResizeComponent }