import { Show, JSX, splitProps, Switch as SolidSwitch, Match, } from "solid-js";
import { isSmall, isExtraSmall, isLarge, isExtraLarge } from "../../utils/sizeUtil"

import { createSignal, createEffect } from "solid-js";
import { Box, Card, Divider, Grid, Stack } from "@suid/material";

import { AccountMenu } from '../components/AppMenuBar/AppBarMenu';
import { ShowHeading } from '../components/SmallComponents/SmallHeader'
import { ResizeComponent } from '../components/MediumComponents/MediumResizerComponent';
import { useNavigate } from "@solidjs/router";
import { isLoggedIn } from '../../utils/isloggedin'

import styled from "@suid/system/styled";

type ResizingType = "left" | "right" | "minimizeLeft" | "minimizeRight" | "maximizeLeft" | "maximizeRight" | "no";

interface CustomerTemplatePageProps {
    //header: string;
    type: "customer" | "candidate" | "supplier" | "brooker"
    leftPaneComponent?: JSX.Element;
    mainComponent: JSX.Element;
    buttonComponent: JSX.Element;

    children: JSX.Element;
}


const CustomerTemplatePage = (props: CustomerTemplatePageProps) => {
    const [local] = splitProps(props, ['children'])

    const navigate = useNavigate();

    if (!isLoggedIn()) {
        navigate('/login');
        return;
    }


    const [mainComponentSize, setMainComponentSize] = createSignal(isExtraSmall() ? 12 : 10);
    const [leftXS, setLeftXS] = createSignal(0);
    const [leftSM, setLeftSM] = createSignal(0);
    const [leftMD, setLeftMD] = createSignal(0);
    const [leftLG, setLeftLG] = createSignal(0);
    const [leftXL, setLeftXL] = createSignal(0);
    const [resizeDirection, setResizeDirection] = createSignal("noResizeDirection"); // | left | right
    const [isResizing, setIsResizing] = createSignal<ResizingType>("no");

    const handleResize = (direction: string) => {
        const size = mainComponentSize();
        if (direction === "left" && size < 12) setMainComponentSize(size + 1)
        if (direction === "right" && size > 2) setMainComponentSize(size - 1)
    };
    const handleMinimize = (direction: string) => {
        const size = mainComponentSize();
        //   if (direction === "left" && size > 1) {
        setIsResizing("minimizeLeft")
        setMainComponentSize(10)
        //leftPaneComponent.Minimize()
        setIsResizing("no")
        //  }
    };
    const handleMaximize = (direction: string) => {
        const size = isExtraLarge() ? 10 : (isLarge() ? 9 : 8)
        setIsResizing("maximizeRight")
        setMainComponentSize(size)
        setIsResizing("no")
    };

    createEffect(() => {
        const size = mainComponentSize();
        setLeftXS(12)
        setLeftSM(12)
        setLeftMD(12 - size)
        setLeftLG(12 - size)
        setLeftXL(12 - size)
    })

    //midnightblue
    //steelblue
    //seagreen
    //slategray 
    //maroon
    const paddingSize = 2;
    const MyCustomer = styled("div")({
        background: "linear-gradient(to right, black 60%, darkslategray 85% )",
        padding: paddingSize,
        borderRadius: 0,
    });
    const MyBrooker = styled("div")({
      
        background: "linear-gradient(to right, black 60%, maroon 85%)",
        padding: paddingSize,
        borderRadius: 0,
    });
    const MyCandidate = styled("div")({
        background: "linear-gradient(to right, black 60%, seagreen 85%)",
        padding: paddingSize,
        borderRadius: 0,
    });
    const MySupplier = styled("div")({
        background: "linear-gradient(to right, black 60%, midnightblue 85%)",
        padding: paddingSize,
        borderRadius: 0,
    });
    return (
        <>
            <AccountMenu />

            <Box sx={{ flexGrow: 1 }}>
                {/*
                <SolidSwitch >
                    <Match when={props.type === "customer"}>
                        <MyCustomer /> <br />
                    </Match>
                    <Match when={props.type === "candidate"}>
                        <MyCandidate /> <br />
                    </Match>
                    <Match when={props.type === "brooker"}>
                        <MyBrooker /> <br />
                    </Match>
                    <Match when={props.type === "supplier"}>
                        <MySupplier /> <br />
                    </Match>
                </SolidSwitch>
    */}
                <Show
                    when={isExtraSmall() || !props?.leftPaneComponent}
                    fallback={
                        <>
                            {/* The screen is large and can hadle two columns. Both left & main column */}

                            {/* Show both Main and Left Component */}
                            <Grid container direction='row' style={{ height: '100vh' }}>

                                {/* Left component */}
                                <Grid item xs={leftXS()} sm={leftSM()} md={leftMD()} lg={leftLG()} xl={leftXL()}
                                    style={{ display: 'flex', }} flexDirection='column' justifyContent='flex-end'
                                >
                                    <ShowHeading pxValue={2} >{props.children}</ShowHeading>

                                    <Card sx={{ height: '100%', margin: '2px' }}>
                                        <Grid container >
                                            < Grid item xs={11} sm={11} md={11} lg={11} xl={11} >

                                                {props.leftPaneComponent}
                                            </Grid>
                                            < Grid item xs={1} sm={1} md={1} lg={1} xl={1}   >
                                                <Box sx={{ display: "flex" }} flexDirection="column" height="100%" alignContent="center" >

                                                    <ResizeComponent handleResize={handleResize} handleMinimize={handleMinimize} handleMaximize={handleMaximize} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                                {/* Main component */}

                                <Grid item xs={12} sm={12} md={mainComponentSize()} lg={mainComponentSize()} xl={mainComponentSize()}>
                                    {props.mainComponent}
                                </Grid>
                            </Grid >
                        </>
                    }
                >
                    <>
                        {/* The screen size does not support a left column */}
                        <Grid container direction='column' >
                            < Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <ShowHeading pxValue={2} >{props.children}</ShowHeading>

                                <Divider />
                                {/* Add props.lefComponent if preffered */}
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {props.mainComponent}
                                </Grid>
                            </Grid>
                        </Grid >
                    </>

                </Show>

            </Box >
        </>
    );
}

export { CustomerTemplatePage }